





























































































































































import Vue from 'vue';
import { required, minValue, maxLength } from 'vuelidate/lib/validators';
import FormBase from '@/components/Form/Form.vue';
import FormField from '@/components/BasicInformation/FormField.vue';
import Textarea from '@/components/InputField/Textarea.vue';
import TextField from '@/components/InputField/TextField.vue';
import Select from '@/components/InputField/Select.vue';
import FileUpload from '@/components/InputField/FileUpload.vue';
import AutoComplete from '@/components/InputField/AutoComplete.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import Business from '@/model/business';
import Document from '@/model/document';
import { PlaceRequest } from '@/model/request/place-request';
import OperationalUnitFilterRequest from '@/model/request/operational-unit-filter-request';
import { mapGetters } from 'vuex';
import { PlaceTrait } from '@/model/enums/place-trait';
import PlaceFilterRequest from '@/model/request/filter/place-filter-request';

export default Vue.extend({
  name: 'PlaceDetailsForm',
  components: {
    FormBase,
    FormField,
    Textarea,
    TextField,
    Select,
    FileUpload,
    AutoComplete,
    Checkbox,
  },
  props: {
    mainBusiness: { type: Object as () => Business },
  },
  data() {
    return {
      submitted: false,
      loading: false,
      form: {
        name: '',
        area: 0,
        coordId: '',
        active: false,
        dangerousActivities: false,
        organizationUnit: '',
        locationCode: '',
        placeTrait: '' as PlaceTrait,
        comment: '',
        typeId: null as number | null,
        documents: [] as Document[],
      },
      attachments: new Array<File>(),
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      area: {
        required,
        minValue: minValue(0),
      },
      coordId: {
        required,
        maxLength: maxLength(200),
      },
      organizationUnit: {
        maxLength: maxLength(2000),
      },
      locationCode: {
        maxLength: maxLength(1000),
      },
      placeTrait: {
        required,
      },
      comment: {
        maxLength: maxLength(5000),
      },
      typeId: {
        required,
      },
    },
  },
  mounted() {
    this.form.placeTrait = this.placeTraits[0];
  },
  computed: {
    ...mapGetters({
      searchText: 'filterStorage/getBasicInformationSearchText',
      businessTypes: 'businessStorage/getBusinessTypes',
      isAutoIncrementing: 'configurationStorage/isPlaceAutoIncrementingId',
      filterOptions: 'filterStorage/placesFilterStorage/getPlaceFilterOptions',
    }),
    placeTraits(): PlaceTrait[] {
      return Object.keys(PlaceTrait) as PlaceTrait[];
    },
  },
  methods: {
    resetForm() {
      this.form.name = '';
      this.form.area = 0;
      this.form.documents = [] as Document[];
      this.form.coordId = '';
      this.form.active = false;
      this.form.dangerousActivities = false;
      this.form.organizationUnit = '';
      this.form.locationCode = '';
      this.form.placeTrait = this.placeTraits[0];
      this.form.comment = '';
      this.form.typeId = null;
      this.attachments = [] as File[];
      this.submitted = false;
    },
    openDialog() {
      (this as any).loadBusionessTypes();
      if (this.isAutoIncrementing) {
        this.$store
          .dispatch('businessStorage/getNextId', this.mainBusiness?.id)
          .then((data: string) => (this.form.coordId = data));
      }
      (this.$refs.dialog as any).open();
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
      this.submitted = false;
    },
    saveChange() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const formData = new FormData();
      formData.append(
        'place',
        new Blob(
          [
            JSON.stringify({
              ...this.form,
              mainId: this.mainBusiness?.id,
              operationalUnitId: parseInt(this.$route.params?.id, 10),
            } as PlaceRequest),
          ],
          {
            type: 'application/json',
          },
        ),
      );
      const filterRequest = new PlaceFilterRequest(this.searchText, this.filterOptions);
      if (this.$route.params.id) {
        filterRequest.operationalUnitId = +this.$route.params.id;
      }
      formData.append(
        'filter',
        new Blob([JSON.stringify(filterRequest.typesToArray())], {
          type: 'application/json',
        }),
      );
      this.attachments.forEach((file: File) => {
        formData.append('file', file);
      });
      this.loading = true;
      this.$store
        .dispatch('businessStorage/save', formData)
        .then((data) => {
          if (data) {
            this.closeDialog();
            this.$emit('added');
          }
        })
        .finally(() => (this.loading = false));
    },
  },
});
