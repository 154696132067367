

















import Vue from 'vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import { mapGetters } from 'vuex';
import BusinessType from '@/model/business-type';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import PlaceFilterOptions from '@/model/filter/place-filter-options';

export default Vue.extend({
  components: {
    Checkbox,
    LoadingIndicator,
  },
  data() {
    return {
      loading: false,
      checkboxValues: [] as BusinessType[],
    };
  },
  mounted() {
    if (this.businessTypes === undefined || this.businessTypes.length === 0) {
      this.loading = true;
      this.$store.dispatch('businessStorage/getTypes').finally(() => (this.loading = false));
    }
  },
  methods: {
    togglePlaceType(businessType: BusinessType) {
      this.$store.commit('filterStorage/placesFilterStorage/toggleType', businessType);
      this.$emit('filterChanged');
    },
    isChecked(businessType: BusinessType): boolean {
      return (this.getToolFilterOptions as PlaceFilterOptions).types.includes(businessType.id);
    },
  },
  computed: {
    ...mapGetters({
      getToolFilterOptions: 'filterStorage/placesFilterStorage/getPlaceFilterOptions',
      businessTypes: 'businessStorage/getBusinessTypes',
    }),
  },
});
