




































































































































































import Vue from 'vue';
import FormField from '@/components/BasicInformation/FormField.vue';
import TextField from '@/components/InputField/TextField.vue';
import Textarea from '@/components/InputField/Textarea.vue';
import Select from '@/components/InputField/Select.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import AutoComplete from '@/components/InputField/AutoComplete.vue';
import Business from '@/model/business';
import { required, minValue, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { PlaceRequest } from '@/model/request/place-request';
import { PlaceTrait } from '@/model/enums/place-trait';
import PlaceFilterRequest from '@/model/request/filter/place-filter-request';

export default Vue.extend({
  name: 'PlaceDetails',
  components: {
    FormField,
    TextField,
    Textarea,
    Select,
    Checkbox,
    AutoComplete,
  },
  props: {
    place: { type: Object as () => Business },
    editing: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    submitted: { type: Boolean, default: false },
    editedName: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
      form: {
        name: this.editedName,
        area: this.place.area,
        coordId: this.place.coordId,
        active: this.place.active,
        dangerousActivities: this.place.dangerousActivities,
        organizationUnit: this.place.organizationUnit,
        locationCode: this.place.locationCode,
        placeTrait: this.place.placeTrait,
        comment: this.place.comment,
        typeId: this.place.type?.id,
      },
    };
  },
  watch: {
    editedName() {
      this.form.name = this.editedName;
    },
  },
  computed: {
    ...mapGetters({
      businessTypes: 'businessStorage/getBusinessTypes',
      isAutoIncrementing: 'configurationStorage/isPlaceAutoIncrementingId',
      searchText: 'filterStorage/getBasicInformationSearchText',
      filterOptions: 'filterStorage/placesFilterStorage/getPlaceFilterOptions',
    }),
    placeTraits(): PlaceTrait[] {
      return Object.keys(PlaceTrait) as PlaceTrait[];
    },
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      area: {
        required,
        minValue: minValue(0),
      },
      coordId: {
        required,
        maxLength: maxLength(200),
      },
      organizationUnit: {
        maxLength: maxLength(2000),
      },
      locationCode: {
        maxLength: maxLength(1000),
      },
      placeTrait: {
        required,
      },
      comment: {
        maxLength: maxLength(5000),
      },
      typeId: {
        required,
      },
    },
  },
  methods: {
    reset() {
      this.form.name = this.editedName;
      this.form.area = this.place.area;
      this.form.coordId = this.place.coordId;
      this.form.active = this.place.active;
      this.form.dangerousActivities = this.place.dangerousActivities;
      this.form.organizationUnit = this.place.organizationUnit;
      this.form.locationCode = this.place.locationCode;
      this.form.placeTrait = this.place.placeTrait;
      this.form.comment = this.place.comment;
      this.form.typeId = this.place.type?.id;
      this.$emit('setSaving', false);
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$emit('setSaving', true);

      const filterRequest = new PlaceFilterRequest(this.searchText, this.filterOptions);
      if (this.$route.params.id) {
        filterRequest.operationalUnitId = +this.$route.params.id;
      }

      this.$store
        .dispatch('businessStorage/modify', {
          id: this.place?.id,
          filter: filterRequest.typesToArray(),
          ...this.form,
        } as PlaceRequest)
        .then((data) => {
          if (data) {
            this.$emit('update', data);
          }
        })
        .finally(() => {
          this.$emit('setSaving', false);
        });
    },
  },
});
