






































































import Vue from 'vue';
import UnitTable from '@/components/BasicInformation/UnitTable.vue';
import PlaceDetailsForm from '@/components/BasicInformation/Places/PlaceDetailsForm.vue';
import AddButton from '@/components/Button/AddButton.vue';
import AutoIdToggle from '@/components/InputField/Toggle.vue';
import SubUnitsPanel from '@/components/BasicInformation/Places/SubUnitsPanel.vue';
import PageOptions from '@/model/page/page-options';
import { mapGetters } from 'vuex';
import AutoIncrementingIdRequest from '@/model/request/auto-incrementing-id-request';
import HeaderBase from '@/components/Header/HeaderBase.vue';
import PlacesFilter from '@/components/BasicInformation/Places/filter/PlacesFilter.vue';

export default Vue.extend({
  name: 'BasicInformationPlaces',
  components: {
    AddButton,
    AutoIdToggle,
    HeaderBase,
    PlaceDetailsForm,
    PlacesFilter,
    SubUnitsPanel,
    UnitTable,
  },
  data() {
    return {
      loading: false,
      headerImage: '/images/place.svg',
      pageOptions: new PageOptions(0, 25),
    };
  },
  mounted() {
    this.fetch();
    this.$emit('setFilter', this.searchText);
    this.$store.dispatch('configurationStorage/getPlaceAutoIncrementingId');
  },
  computed: {
    ...mapGetters({
      places: 'businessStorage/getPageableOperationalUnitBusinesses',
      searchText: 'filterStorage/getBasicInformationSearchText',
      filtering: 'filterStorage/isFiltering',
      isAutoIncrementing: 'configurationStorage/isPlaceAutoIncrementingId',
      isAdmin: 'currentUserStorage/isAdmin',
    }),
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('businessStorage/getOperationalUnitBusinessPage', {
          pageOptions: this.pageOptions,
          operationalUnitId: this.$route.params?.id,
        })
        .finally(() => (this.loading = false));
    },
    pageOptionsChanged(newPageOptions: PageOptions): void {
      this.pageOptions = newPageOptions;
      this.fetch();
    },
    textFiltering() {
      this.$store.dispatch('businessStorage/getOperationalUnitBusinessPage', {
        pageOptions: new PageOptions(0, 25),
        operationalUnitId: this.$route.params?.id,
      });
    },
    changeAutoId(value: boolean) {
      this.$store.dispatch('configurationStorage/setPlaceAutoIncrementingId', new AutoIncrementingIdRequest(value));
    },
  },
});
