





















































import Vue from 'vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import Table from '@/components/Table/Table.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import PlaceDetailsForm from '@/components/BasicInformation/Places/PlaceDetailsForm.vue';
import Button from '@/components/Button/Button.vue';
import Business from '@/model/business';
import PageOptions from '@/model/page/page-options';
import OperationalUnitFilterRequest from '@/model/request/operational-unit-filter-request';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'PlaceSubUnits',
  components: {
    SearchBar,
    Table,
    LoadingIndicator,
    InfiniteScroll,
    PlaceDetailsForm,
    Button,
  },
  props: {
    place: { type: Object as () => Business },
  },
  mounted() {
    this.fetch(this.pageOptions, this.filterOptions);
  },
  data() {
    return {
      loading: false,
      uploading: false,
      pageOptions: new PageOptions(0, 20),
      filterOptions: new OperationalUnitFilterRequest(''),
      documentIcon: '/images/documents.svg',
      tableFields: [
        {
          text: this.$t('basic-information.place.unique-id'),
          key: 'id',
          label: this.$t('basic-information.place.unique-id'),
          thStyle: 'width: 22.5%',
          sortable: true,
        },
        {
          text: this.$t('basic-information.place.business-name'),
          key: 'name',
          label: this.$t('basic-information.place.business-name'),
          thStyle: 'width: 22.5%',
          sortable: true,
        },
        {
          text: this.$t('basic-information.place.type'),
          key: 'type',
          label: this.$t('basic-information.place.type'),
          thStyle: 'width: 22.5%',
        },
        {
          text: this.$t('basic-information.place.area'),
          key: 'area',
          label: this.$t('basic-information.place.area'),
          thStyle: 'width: 22.5%',
          sortable: true,
        },
        {
          text: this.$t('basic-information.place.documents'),
          key: 'documents',
          label: this.$t('basic-information.place.documents'),
          thStyle: 'width: 10%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ subUnits: 'businessStorage/getPageableSubUnits' }),
  },
  methods: {
    fetch(pageOptions: PageOptions, filterOptions: OperationalUnitFilterRequest) {
      this.loading = true;
      this.$store
        .dispatch('businessStorage/getSubUnitsPageable', {
          id: this.place?.id,
          pageOptions,
          filterOptions,
        })
        .finally(() => (this.loading = false));
    },
    textFiltering(searchText: string) {
      this.filterOptions.text = searchText;
      this.fetch(this.pageOptions, this.filterOptions);
    },
    pageOptionsChanged(newPageOptions: PageOptions): void {
      this.pageOptions = newPageOptions;
      this.fetch(this.pageOptions, this.filterOptions);
    },
    infiniteLoad(): void {
      this.pageOptions.page++;
      this.fetch(this.pageOptions, this.filterOptions);
    },
  },
});
