var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex row m-0"},[_c('SearchBar',{ref:"textFilter",staticClass:"col-9 m-0 py-0",attrs:{"hasPrependIcon":""},on:{"filter":_vm.textFiltering}}),_c('div',{staticClass:"col-3 p-0 pl-4"},[(_vm.uploading)?_c('LoadingIndicator',{staticClass:"mx-auto mt-3"}):_vm._e(),(_vm.canDoBasicInformationActions)?_c('PlaceDetailsForm',{attrs:{"mainBusiness":_vm.place},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('Button',{attrs:{"icon":"fas fa-plus","text":_vm.$t('basic-information.place.form.add-unit-title')}})]},proxy:true}],null,false,3797804974)}):_vm._e()],1)],1),_c('div',{staticClass:"documents-table custom-scrollbar position-relative mt-4"},[_c('Table',{attrs:{"isInfiniteScroll":"","isDetail":"","isLoading":_vm.loading,"data":_vm.subUnits,"fields":_vm.tableFields,"formattedColumns":['id', 'name', 'type', 'area', 'documents'],"stickyHeader":"27.5rem"},on:{"newPageOption":_vm.pageOptionsChanged},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"tabled-text"},[_vm._v(_vm._s(rowData.coordId))])]}},{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"unit-name",on:{"click":function($event){return _vm.$emit('openDetails', rowData)}}},[_vm._v(_vm._s(rowData.name))])]}},{key:"type",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"tabled-text"},[_vm._v(_vm._s(rowData.type.name))])]}},{key:"area",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"tabled-text"},[_vm._v(_vm._s(rowData.area)+" ㎡")])]}},{key:"documents",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex justify-center"},[(rowData.documents && rowData.documents.length)?_c('v-img',{staticClass:"document-icon",attrs:{"src":_vm.documentIcon},on:{"click":function($event){return _vm.$emit('openDocuments', rowData)}}}):_c('span',[_vm._v("-")])],1)]}}])}),(!_vm.loading && _vm.subUnits.currentPage < _vm.subUnits.totalPages)?_c('InfiniteScroll',{attrs:{"currentPage":_vm.subUnits.currentPage,"totalPages":_vm.subUnits.totalPages},on:{"infiniteLoad":_vm.infiniteLoad}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }