




import Vue from 'vue';
import DeleteButton from '@/components/DetailedViewPopup/ActionButton.vue';
import Notification from '@/model/notification';
import Business from '@/model/business';

export default Vue.extend({
  name: 'DeletePlace',
  components: {
    DeleteButton,
  },
  props: {
    place: { type: Object as () => Business },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deletePlace() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            `${this.$t('basic-information.place.delete', {
              name: this.place.name,
            }).toString()} ${
              this.place?.units?.length > 0 ? this.$t('basic-information.place.delete-units-warning') : ''
            }`,
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store
              .dispatch('businessStorage/delete', this.place)
              .then(() => this.$emit('delete'))
              .finally(() => (this.loading = false));
          }
        });
    },
  },
});
