
































































import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import PlaceDetailsForm from '@/components/BasicInformation/Places/PlaceDetailsForm.vue';
import PlaceDetailedView from '@/components/BasicInformation/Places/PlaceDetailedView.vue';
import Business from '@/model/business';
import Checkbox from '@/components/InputField/Checkbox.vue';

export default Vue.extend({
  name: 'SubUnitsPanel',
  components: {
    LoadingIndicator,
    PlaceDetailsForm,
    PlaceDetailedView,
    Checkbox,
  },
  props: {
    business: { type: Object as () => Business },
  },
  data() {
    return {
      loading: false,
      panel: undefined,
      openedUnits: 0,
      selectedPlace: {} as Business,
    };
  },
  methods: {
    unitOpened(value: boolean) {
      value ? this.openedUnits++ : this.openedUnits--;
    },
    update() {
      this.$forceUpdate();
    },
    openDetailedView(item: Business) {
      this.selectedPlace = item;
      this.$nextTick(() => {
        (this.$refs.detailedView as any).open();
      });
    },
    closeDetailedView() {
      this.$emit('update');
      this.selectedPlace = {} as Business;
    },
  },
});
