


















































import Vue from 'vue';
import DetailedViewBase from '@/components/DetailedViewPopup/DetailedViewBase.vue';
import ActionButton from '@/components/DetailedViewPopup/ActionButton.vue';
import Navigation from '@/components/Dashboard/DetailedView/Navigation.vue';
import PlaceDetails from '@/components/BasicInformation/Places/Details.vue';
import PlaceSubUnits from '@/components/BasicInformation/Places/SubUnits.vue';
import PlaceDocuments from '@/components/BasicInformation/Places/Documents.vue';
import DeletePlace from '@/components/BasicInformation/Places/DeletePlace.vue';
import TextField from '@/components/InputField/TextField.vue';
import { menus } from '@/constants/menu/place-menus';
import Business from '@/model/business';
import { required, maxLength } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'PlaceDetailedView',
  components: {
    DetailedViewBase,
    ActionButton,
    Navigation,
    PlaceDetails,
    PlaceSubUnits,
    PlaceDocuments,
    DeletePlace,
    TextField,
  },
  props: {
    place: { type: Object as () => Business },
  },
  data() {
    return {
      menus,
      opened: false,
      editing: false,
      saving: false,
      submitted: false,
      openedPlace: this.place,
      openedDirectly: this.place,
      activeComponent: 'PlaceDetails',
      name: this.place.name,
    };
  },
  computed: {
    isDetailsPage(): boolean {
      return this.activeComponent === 'PlaceDetails';
    },
    isDocumentsPage(): boolean {
      return this.activeComponent === 'PlaceDocuments';
    },
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(100),
    },
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      if (this.openedPlace === this.openedDirectly) {
        this.opened = false;
        this.$emit('close');
      } else {
        this.setOpenedPlace(this.openedDirectly);
        this.activeComponent = 'PlaceSubUnits';
        this.$nextTick(() => (this.$refs.details as any).fetch());
      }
      this.resetEdition();
    },
    edit() {
      (this as any).loadBusionessTypes();
      (this.$refs.details as any).reset();
      this.editing = true;
    },
    save() {
      this.submitted = true;
      (this.$refs.details as any).save();
    },
    resetEdition() {
      this.editing = false;
      this.name = this.openedPlace.name;
      if (this.isDetailsPage) {
        (this.$refs.details as any).reset();
      }
    },
    update(modifiedPlace: Business) {
      this.openedPlace = modifiedPlace;
      this.resetEdition();
      this.$emit('update');
    },
    setSaving(value: boolean) {
      this.saving = value;
      if (!value) {
        this.submitted = false;
      }
    },
    removeDeleted() {
      this.close();
    },
    openSubDetails(subPlace: Business) {
      this.setOpenedPlace(subPlace);
      this.activeComponent = 'PlaceDetails';
    },
    openSubDocuments(subPlace: Business) {
      this.setOpenedPlace(subPlace);
      this.activeComponent = 'PlaceDocuments';
    },
    setOpenedPlace(place: Business) {
      this.openedPlace = place;
      this.name = this.openedPlace.name;
    },
  },
});
