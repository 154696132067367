import { i18n } from '@/i18n';

export const menus = [
  {
    name: i18n.t('basic-information.place.details'),
    icon: '/images/info.svg',
    componentName: 'PlaceDetails',
  },
  {
    name: i18n.t('basic-information.place.units'),
    icon: '/images/sub-unit.svg',
    componentName: 'PlaceSubUnits',
  },
  {
    name: i18n.t('basic-information.place.documents'),
    icon: '/images/documents.svg',
    componentName: 'PlaceDocuments',
  },
];
