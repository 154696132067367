














































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { TranslateResult } from 'vue-i18n';
import { i18n } from '@/i18n';
import StatusFilter from '@/components/BasicInformation/Places/filter/StatusFilter.vue';
import DangerousActivitiesFilter from '@/components/BasicInformation/Places/filter/DangerousActivitiesFilter.vue';
import PlaceTraitFilter from '@/components/BasicInformation/Places/filter/PlaceTraitFilter.vue';
import PlaceTypeFilter from '@/components/BasicInformation/Places/filter/PlaceTypeFilter.vue';
import PlaceFilterOptions from '@/model/filter/place-filter-options';

interface FilterSubMenu {
  name: TranslateResult;
  componentName: string;
}

const filterRows: FilterSubMenu[] = [
  {
    name: i18n.t('place.table-header.type'),
    componentName: 'PlaceTypeFilter',
  },
  {
    name: i18n.t('place.table-header.place-trait'),
    componentName: 'PlaceTraitFilter',
  },
  {
    name: i18n.t('place.table-header.status'),
    componentName: 'StatusFilter',
  },
  {
    name: i18n.t('place.table-header.dangerous-activities'),
    componentName: 'DangerousActivitiesFilter',
  },
];

export default Vue.extend({
  name: 'ToolFilter',
  components: {
    DangerousActivitiesFilter,
    PlaceTraitFilter,
    PlaceTypeFilter,
    StatusFilter,
  },
  data() {
    return {
      mainActive: false,
      filter: '/images/filter.svg',
      activeComponent: {
        name: '',
        componentName: '',
      },
      filterMenus: filterRows,
    };
  },
  computed: {
    ...mapGetters({
      filterOptions: 'filterStorage/placesFilterStorage/getPlaceFilterOptions',
      isFiltering: 'filterStorage/placesFilterStorage/isFiltering',
    }),
  },
  methods: {
    openMain() {
      this.mainActive = true;
    },
    closeMain() {
      this.mainActive = false;
    },
    openSubmenu(newComponent: { name: string; componentName: string }) {
      this.activeComponent = newComponent;
      this.mainActive = false;
    },
    closeSubmenu() {
      this.mainActive = true;
      this.activeComponent = { name: '', componentName: '' };
    },
    resetFilter() {
      this.$store.commit('filterStorage/placesFilterStorage/clearPlaceFilterOptions');
      this.filterChanged();
    },
    isModified(componentName: string): boolean {
      let isFilterSet = false;
      const filterOptions: PlaceFilterOptions = this.filterOptions;

      switch (componentName) {
        case 'PlaceTypeFilter':
          isFilterSet = filterOptions.types.length > 0;
          break;
        case 'PlaceTraitFilter':
          isFilterSet = filterOptions.placeTrait !== undefined;
          break;
        case 'StatusFilter':
          isFilterSet = filterOptions.active !== undefined;
          break;
        case 'DangerousActivitiesFilter':
          isFilterSet = filterOptions.dangerousActivities !== undefined;
          break;
      }
      return isFilterSet;
    },
    filterChanged() {
      this.$emit('filterChanged');
    },
  },
});
